<template>
  <v-container fluid grid-list-xs class="bgLocation pt-16 px-0 ma-0">
    <v-row justify="center" class="text-center mx-0 px-0 my-16">
      <v-col cols="12" sm="4" class="pa-0 ma-0">
        <div class="subSection">
          <v-chip label class="red"
            ><p class="mb-0 white--text" transition="scroll-x-transition">
              Unde se afla institutia?
            </p></v-chip
          >
        </div>
        <h1 class="mb-16">Locatie gradinita</h1>
      </v-col>
    </v-row>
    <v-row justify="center" class="ma-0 pa-0">
      <v-col cols="12" md="5">
        <div class="boxBlueOuter">
          <v-card class="pa-4 my-14" elevation="0"
            ><v-carousel
              :continuous="true"
              :cycle="true"
              hide-delimiter-background
              delimiter-icon="mdi-minus"
            >
              <v-carousel-item
                v-for="(item, i) in promotionPhotos"
                :key="'A' + i"
                :src="item.url"
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
          </v-card></div
      ></v-col>
      <v-col cols="12" md="5">
        <v-row
          justify="space-between"
          v-for="(item, i) in items"
          :key="'B' + i"
          class="pl-10"
        >
          <v-col cols="12" md="1">
            <v-avatar :color="item.color" size="120" :class="item.class">
              <span class="white--text text-h2">{{ item.number }}</span>
            </v-avatar>
          </v-col>
          <v-col cols="12" md="9">
            <v-card outlined color="transparent">
              <h2>{{ item.title }}</h2>
              <p>{{ item.description }}</p>
              <v-row
                justify="center"
                class="my-2"
                v-if="item.descriptionExtension"
              >
                <v-btn
                  color="grey"
                  text
                  class="custom-transform-class text-none"
                  @click="(show = !show), (showIndex = i)"
                >
                  <v-card-text class="mt-1 ml-2">
                    <span class="black--text">Citeste mai mult</span>
                    <v-icon color="purple">{{
                      show && showIndex === i
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    }}</v-icon>
                  </v-card-text>
                </v-btn>
                <v-expand-transition v-if="showIndex === i">
                  <div v-show="show">
                    <v-divider class="mt-4"></v-divider>
                    <p class="ml-3">{{ item.descriptionExtension }}</p>
                  </div>
                </v-expand-transition>
              </v-row></v-card
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="pt-16 mx-0 px-0 sectDist mb-10">
    <div class="mapouter pt-16" style="width: 100%; max-width: 100%">
      <div class="gmap_canvas mb-16">
        <iframe
          width="100%"
          height="400"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=%C8%98oseaua%20Unirii%20327,%20Comuna%20Corbeanca,%20Romania&t=&z=15&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </div>
  </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Location",
  data() {
    return {
      show: false,
      showIndex: 0,
      items: [
        {
          title: "Locatie perfecta",
          number: "1",
          color: "red",
          class: "boxRed",
          description:
            "Gradinita si Scoala Primara Anastasya este situata in apropierea unei oaze de verdeata, la numai 200 m de padurea Tamas, departe de aglomeratia urbana si de poluare. Locatia dispune de o curte de 2000 mp.  ​",
          descriptionExtension:
            "Astfel, situandu-ne printre putinele institutii care beneficiaza de un asemenea spatiu, destinat atat jocurilor in aer liber, cat si desfasurarii unor activitati didactice. Spatiul de joaca este amenajat cu gazon, tobogane, leagane, nisipare, jucarii pentru exterior, etc.",
        },
        {
          title: "Interior spatios",
          number: "2",
          color: "orange",
          class: "boxOrange",
          description:
            "Institutia functioneaza intr-o cladire noua, dispunand de 10 sali, repartizate pe 2 nivele + parter. Salile de clasa sunt foarte spatioase si luminoase, dotate cu mobilier nou si cu materiale didactice adecvate derularii activitatii educative. ",
          descriptionExtension:
            "Dormitoarele sunt distincte de salile de clasa si sunt dotate cu grupuri sanitare proprii. Paturile au saltele ortopedice, iar pernele sunt antialergice. Copiii sunt supravegheati pe toata durata programului de odihna.​ Avem si o salina recreationala, unde copiii se pot relaxa.",
        },
        {
          title: "Transport",
          number: "3",

          color: "lightPurple",
          class: "boxLightPurple",
          description:
            "Transportul poate fi asigurat la solicitarea parintiilor daca acesta se afla in aria noastra de acoperire.​",
        },
      ],
      images: [
        {
          src: "heroImg2.jpeg",
        },
        {
          src: "heroImg2.jpeg",
        },
        {
          src: "heroImg2.jpeg",
        },
      ],
    };
  },
  computed: {
    ...mapState(["promotionPhotos"]),
  },
};
</script>
<style scoped>
.bgLocation {
  background-image: url("../assets/slides/Slide3.jpg");
  width: 100%;
  min-height: 100vh;
  background-size: cover;
}
.mapouter {
  position: relative;
  text-align: right;
  height: 400px;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  height: 400px;
  width: 100%;
}
@media only screen and (max-width: 200px) {
  .mapouter {
    padding: 0 !important; /* Remove any padding that might push it off-center */
  }
  
  .v-row {
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
}
</style>

