<template>
  <v-container fluid grid-list-xs class="sectDist bgContact">
    <v-row class="mx-0">
      <v-row class="justify-center text-center mx-0" id="contact">
        <v-col cols="12" sm="4">
          <div class="subSection">
            <v-chip label class="red"
              ><p class="mb-0 white--text">Vrei sa iei legatura?</p></v-chip
            >
          </div>
          <h1 class="mb-3">Contacteaza-ne</h1>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mx-0">
        <v-col cols="11" sm="6">
          <v-card class="pa-6 mb-10" max-width="800px">
            <v-row class="justify-center">
              <v-col cols="12" sm="3" class="mt-0 pt-0"
                ><v-subheader>Director</v-subheader>
                <p class="ml-4">0724.21.58.32</p></v-col
              >
              <v-col cols="12" sm="9" class="mt-0 pt-0">
                <v-subheader>Secretariat</v-subheader>
                <p class="ml-4">0314.361.115 / 0771.316.372 / 0212.666.066</p>
              </v-col>
              <v-col cols="12" sm="3" class="mt-0 pt-0"
                ><v-subheader>Administrator</v-subheader>
                <p class="ml-4">0766.42.55.77</p></v-col
              >
              <v-col cols="12" sm="9" class="mt-0 pt-0">
                <v-subheader>Email</v-subheader>
                <p class="ml-4">office@anastasya.ro / flori_meila@yahoo.com</p>
              </v-col>
            </v-row>
          </v-card>
          <v-img
            class="hidden-xs-only"
            :src="require('../assets/contact.jpeg')"
            max-width="800px"
            height="49%"
          />
        </v-col>
        <v-col cols="11" sm="5">
          <v-card class="mx-auto" v-if="!emailSent">
            <v-row class="justify-center mt-2 px-10">
              <v-card-title class="text-center">
                <span class="text-h5">Completeaza mai jos </span>
              </v-card-title>
            </v-row>
            <v-form>
              <v-card-text class="px-8">
                <v-divider></v-divider>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Nume*"
                        v-model="first"
                        required
                        hint="Completeaza cu numele tau."
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="middle"
                        label="Mijlociu* (optional)"
                        hint="Completeaza cu prenumele tau."
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        label="Prenume*"
                        v-model="last"
                        hint="Completeaza cu prenumele tau."
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Email*"
                        type="email"
                        v-model="email"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Subiect*"
                        v-model="subject"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        rows="5"
                        label="Descriere*"
                        type="text"
                        v-model="message"
                        required
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-9 px-10">
                <v-spacer></v-spacer>
                <v-btn color="red" text> Reseteaza </v-btn>
                <v-btn color="black" text @click="emailSent = true">
                  Trimite
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
          <v-card class="mx-auto" v-if="emailSent">
            <v-row class="justify-center mt-3 px-10">
              <v-card-title class="text-center my-10">
                <span class="text-h3">Mesajul tau a fost trimis! </span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-title class="text-center text-h4 smallTxt">
                <span>Multumim pentru ca ne-ai contactat!</span>
              </v-card-title>
              <v-card-text class="text-center">
                Echipa noastra lucreaza chiar acum pentru ati trimite un
                raspuns.
              </v-card-text>
              <v-card-text class="text-center">
                In cel mult 24 de ore in zilele lucratoare!
              </v-card-text>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
export default {
  name: "Contact",

  data() {
    return {
      last: "",
      middle: "",
      first: "",
      email: "",
      subject: "",
      message: "",
      emailSent: false,
    };
  },
  methods: {
    sentMail() {
      let name = [this.last, this.middle, this.first].join(" ");
      let date = new Date();

      let message = {
        name: name,
        email: this.email,
        subject: this.subject,
        message: this.message,
        date: date.toDateString(),
      };
      db.collection("submissions")
        .add(message)
        .then(() => {
          this.last = "";
          this.middle = "";
          this.first = "";
          this.email = "";
          this.subject = "";
          this.message = "";
        });
    },
  },
};
</script>
<style scoped>
.padBottom {
  padding-bottom: 80px;
}
.bgContact {
  background-image: url("../assets/slides/Slide4.jpg");
  width: 100%;
  min-height: 100vh;
  background-size: cover;
}
</style>
