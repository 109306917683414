<template>
  <v-container fluid grid-list-xs class="bgGallery sectDist">
    <v-row class="mx-0">
      <v-col
        cols="12"
        sm="3"
        class="d-flex child-flex pa-0 ma-0"
        v-for="(item, i) in albumsList[0].albums"
        :key="i"
        id="gallery"
      >
        <v-img
          v-show="
            (i <= 7 && !$vuetify.breakpoint.xs) ||
            (i <= 3 && $vuetify.breakpoint.xs)
          "
          :src="item.url"
          @click="dialog = true"
          aspect-ratio="1"
          cover
          height="400px"
          class="enlarge align-center"
        >
          <v-card
            width="280px"
            height="130px"
            class="text-center py-4 opacity mx-auto"
          >
            <h4 class="white--text setHeight">{{ item.name }}</h4>

            <v-btn
              color="orange"
              class="mt-2 boxOrange white--text"
              @click="(dialog = true), (selectedAlbum = item)"
              >vezi poze</v-btn
            >
          </v-card>
        </v-img>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="orange">
          <v-btn icon dark @click="dialog = false">
            <v-icon class="black--text">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="black--text hidden-xs-only"
            >Albume Gradinita</v-toolbar-title
          >
          <div class="d-inline-flex my-4">
            <v-select
              :items="albumYears"
              color="purple"
              item-color="purple"
              v-model="year"
              chips
              @change="setMainAlbums()"
              filled
              label="Anul albumelor"
              :placeholder="year"
              class="pt-7 ml-5 hidden-sm-and-up"
              style="width: 150px"
            ></v-select>
          </div>
        </v-toolbar>

        <v-row justify="space-between" class="mt-1 ma-0">
          <v-col cols="11" offset-sm="1" sm="3">
            <v-select
              :items="albumYears"
              color="purple"
              item-color="purple"
              v-model="year"
              chips
              @change="setMainAlbums()"
              label="Anul albumelor"
              :placeholder="year"
              class="pt-7 ml-5 hidden-xs-only"
              style="width: 150px"
            ></v-select>
            <div v-if="mainAlbums.length === 0" class="hidden-xs-only">
              <p>
                Nu exista albume din anul selectat. Va rugam sa incercati un alt
                an!
              </p>
              <p>Multumim!</p>
            </div>
            <div
              v-if="mainAlbums.length === 0"
              class="hidden-sm-and-up ma-0 pa-0"
            >
              <p class="ma-0 pa-0">
                Nu exista albume din acest an. Incercati din nou!
              </p>
            </div>
            <v-list class="hidden-xs-only">
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in mainAlbums"
                  :key="'A' + i"
                  @click="selectedAlbum = item"
                  class="px-0"
                >
                  <v-avatar class="profile mr-5" color="grey" size="50" tile>
                    <v-img :src="item.url" />
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-tabs show-arrows color="black" class="hidden-sm-and-up">
              <v-tabs-slider color="black"></v-tabs-slider>
              <v-tab v-for="(item, i) in mainAlbums" :key="'B' + i">
                <div @click="albumId = item.docId">
                  <h6>{{ item.name }}</h6>
                </div>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="12" sm="8">
            <v-sheet center-active>
              <v-slide-group v-model="model" center-active>
                <v-slide-item
                  v-for="(photo, i) in selectedAlbum.photos"
                  :key="'C' + i"
                  class="mb-6 hidden-xs-only"
                >
                  <v-card
                    class="ma-2"
                    height="90"
                    width="90"
                    @click="model = i"
                  >
                    <v-img
                      height="90"
                      width="90"
                      :src="photo.url"
                      :lazy-src="photo.url1080x720"
                      alt
                    />
                  </v-card>
                </v-slide-item>
                <v-slide-item
                  v-for="(photo, i) in selectedAlbum.photos"
                  :key="'D' + i"
                  class="mb-6 hidden-sm-and-up"
                >
                  <v-card
                    class="mr-2"
                    height="60"
                    width="60"
                    @click.prevent="imgNr = i"
                  >
                    <v-img
                      height="60"
                      width="60"
                      :src="photo.url"
                      :lazy-src="photo.url1080x720"
                      alt
                    />
                  </v-card>
                </v-slide-item>
              </v-slide-group>
              <v-expand-transition>
                <v-sheet v-if="model != null">
                  <v-row align="center" class="d-flex">
                    <v-col
                      v-for="(photo, i) in selectedAlbum.photos"
                      :key="'E' + i"
                      class="hidden-xs-only"
                    >
                      <v-img
                        v-show="i === model && photo.url"
                        background-color="grey"
                        :src="photo.url"
                        :lazy-src="photo.url1080x720"
                        height="70vh"
                        width="100vh"
                        style="position: absolute; top: 230px; left: 650px"
                      >
                        <v-layout row wrap justify-space-between>
                          <v-col cols="1" class="ml-5">
                            <v-btn
                              @click="prevPhoto()"
                              color="black"
                              fab
                              elevation="1"
                              class="marginTop changeOpacity"
                            >
                              <v-icon size="50px" color="white" dark
                                >mdi-arrow-left-bold</v-icon
                              >
                            </v-btn>
                          </v-col>
                          <v-col cols="1" class="mr-5">
                            <v-btn
                              @click="nextPhoto()"
                              fab
                              color="black"
                              elevation="1"
                              class="marginTop changeOpacity"
                            >
                              <v-icon size="50px" color="white" dark
                                >mdi-arrow-right-bold</v-icon
                              >
                            </v-btn>
                          </v-col>
                        </v-layout>
                      </v-img>
                    </v-col>
                  </v-row>
                  <v-row
                    class="hidden-sm-and-up ma-0 pa-0 mx-0 mt-5"
                    align="center"
                  >
                    <div
                      v-for="(photo, i) in selectedAlbum.photos"
                      :key="'F' + i"
                    >
                      <v-img
                        cover
                        max-width="400px"
                        height="65vh"
                        v-show="i === imgNr && photo.url"
                        :src="photo.url"
                        :lazy-src="photo.url"
                        alt
                      />
                    </div>
                  </v-row>
                </v-sheet>
              </v-expand-transition>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "GalleryView",
  data() {
    return {
      dialog: false,
      menu: false,
      year: "2022",
      modal: false,
      model: 0,
      imgNr: 0,
      photos: [],
      selectedAlbum: {},
      albumId: "",
    };
  },
  computed: {
    ...mapState(["albumsList", "albumYears", "mainAlbums"]),
  },
  methods: {
    setMainAlbums() {
      this.albumsList.forEach((album) => {
        if (album.year === this.year) {
          console.log(album);

          this.$store.commit("SET_MAIN_ALBUMS", album.albums);
          this.selectedAlbum = album.albums[0];
        }
      });
    },
    prevPhoto() {
      if (this.model >= 1) {
        this.model = this.model - 1;
      }
    },
    nextPhoto() {
      if (this.model < this.selectedAlbum.photos.length - 1) {
        this.model = this.model + 1;
      }
    },
  },
};
</script>
<style scoped>
.enlarge {
  cursor: pointer;
  transition: transform 0.6s ease-out;
}

.enlarge:hover {
  z-index: 1;
  transform: scale(1.1);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.247);
  transition: transform 0.6s ease-out;
}
.setHeight {
  height: 50px;
}
.content {
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
}
.content:hover {
  background: rgba(114, 82, 82, 0);
  transition: transform 0.6s ease-out;
}
.opacity {
  background: rgba(000, 000, 000, 0.4); /* Black background with 0.5 opacity */
}
.marginTop {
  margin-top: 320px;
}
.changeOpacity {
  opacity: 0.8;
}
.bgGallery {
  background-image: url("../assets/slides/Slide4.jpg");
  width: 100%;
  min-height: 110vh;
  background-size: cover;
}
</style>
