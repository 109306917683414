<template>
  <v-container fluid grid-list-xs class="sectDist">
    <v-row justify="center" class="mx-0" id="funds">
      <h1>Fonduri</h1>
    </v-row>
    <v-row justify="center" align="center" class="mx-0 mt-10 hidden-xs-only">
      <!-- Left half of the list -->
      <v-col cols="12" sm="3" class="py-8 mx-0">
        <v-row 
          v-for="(item, i) in leftHalfList" 
          :key="i"
          justify="center"
          class="mb-4"
        >
          <v-icon class="text-center" :color="item.color" size="50">mdi-calendar</v-icon>
          <h2 class="mt-2 ml-5">In anul {{ item.date }},</h2>
          <v-card class="my-4 px-4 pt-4" max-width="300px">
            <p>{{ item.description }}</p>
          </v-card>
        </v-row>
      </v-col>

      <!-- Middle image -->
      <v-col cols="12" sm="4" class="text-center">
        <v-img cover :src="require('../assets/working.jpeg')" height="980px" />
      </v-col>

      <!-- Right half of the list -->
      <v-col cols="12" sm="3" class="py-8 mx-0">
        <v-row 
          v-for="(item, i) in rightHalfList" 
          :key="i"
          justify="center"
          class="mb-4"

        >
          <v-icon class="text-center" :color="item.color" size="50">mdi-calendar</v-icon>
          <h2 class="mt-2 ml-5">In anul {{ item.date }},</h2>
          <v-card class="my-4 px-4 pt-4" max-width="300px">
            <p>{{ item.description }}</p>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mx-0 mt-10 hidden-sm-and-up">
      <!-- Left half of the list -->
      <v-col cols="12" sm="3" class="py-8 mx-0">
        <v-row 
          v-for="(item, i) in list" 
          :key="i"
          justify="center"
          class="mb-4"
        >
          <v-icon class="text-center" :color="item.color" size="50">mdi-calendar</v-icon>
          <h2 class="mt-2 ml-5">In anul {{ item.date }},</h2>
          <v-card class="my-4 px-4 pt-4" max-width="300px">
            <p>{{ item.description }}</p>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Funds",
  data() {
    return {
      list: [
      {
          date: "2024",
          description:
            "de finantare in baza costului standard per elev in valoare de 870000 Ron (salarii, taxe), 70000 bunuri si servicii. Total 940000 Ron.",
          color: "orange",
        },
      {
          date: "2023",
          description:
            "de finantare in baza costului standard per elev in valoare de 731000 Ron din care 403382 salarii, 257618 taxe, 70000 bunuri si servicii.",
          color: "green",
        },
        {
          date: "2022",
          description:
            "de finantare in baza costului standard per elev in valoare de 816000 Ron din care 423364 salarii, 323636 taxe, 69000 bunuri si servicii.",
          color: "yellow",
        },
        {
          date: "2021",
          description:
            "de finantare in baza costului standard per elev in valoare de 580000 Ron din care 309888 salarii, 225112 taxe, 45000 bunuri si servicii.",
          color: "purple",
        },
        {
          date: "2020",
          description:
            "de finantare in baza costului standard per elev in valoare de 720000 Ron din care 400450 salarii, 273550 taxe, 46000 bunuri si servicii.",
          color: "red",
        },

        {
          date: "2019",
          description:
            "de finanțare in baza costului standard per elev in valoare de 417000 RON, din care 226858 salarii, 165142 taxe, 25000 bunuri si servicii.",
          color: "blue",
        },
        {
          date: "2018",
          description:
            "de finantare in baza costului standard per elev in valoare de 210000 Ron din care 113107 salarii, 80893 taxe, 16000 bunuri si servicii.",
          color: "pink",
        },
        {
          date: "2017",
          description:
            "de finantare in baza costului standard per elev in valoare de 176000 Ron din care 163000 taxe salariale si 13000 bunuri si servicii.",
          color: "yellow",
        },
      ],
    
    };
  },
  computed: {
    leftHalfList() {
      return this.list.filter((_, index) => index % 2 === 0);
    },
    rightHalfList() {
      return this.list.filter((_, index) => index % 2 !== 0);
    }
  }
};
</script>
<style></style>
