<template>
  <v-container fluid grid-list-xs class="ma-0 pa-0">
    <Hero />
    <!-- <Covid /> -->
    <Values />
    <Location />
    <!-- <Investments /> -->
    <!-- <Levels /> -->
    <!-- <Food /> -->
    <!-- <Team /> -->
    <!-- <Partners /> -->
    <Gallery />
    <Entries />
    <Contact />
  </v-container>
</template>

<script>
import Hero from "../components/Hero.vue";
// import Covid from "../components/Covid.vue";
import Values from "../components/Values.vue";
import Location from "../components/Location.vue";
// import Investments from "../components/Investments.vue";
// import Levels from "../components/Levels.vue";
// import Food from "../components/Food.vue";
// import Team from "../components/Team.vue";
import Gallery from "../components/Gallery.vue";
import Entries from "../components/Entries.vue";
// import Partners from "../components/Partners.vue";
import Contact from "../components/Contact.vue";

export default {
  name: "Home",

  components: {
    Hero,
    // Covid,
    Values,
    Location,
    // Investments,
    // Levels,
    // Food,
    // Team,
    Gallery,
    Entries,
    // Partners,
    Contact,
  },
};
</script>
