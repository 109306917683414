<template>
  <v-container fluid grid-list-xs class="bgValues ">
    <v-row justify="center" class="text-center my-10 mx-0">
      <v-col cols="12" sm="4">
        <div class="subSection">
          <v-chip label class="red"
            ><p class="mb-0 white--text" transition="scroll-x-transition">
              Despre noi
            </p></v-chip
          >
        </div>
        <h1 class="mb-16">Valorile noastre</h1>
      </v-col>
    </v-row>
    <v-row justify="space-around" class="text-center mx-16">
      <div v-for="(item, i) of items" :key="i" class="pb-8">
        <div :class="item.classOuter" class="roundedShinyCard ma-0 pa-0">
          <v-card
            class="ma-0 pa-0 roundedShinyCard"
            elevation="0"
            :class="{
              xsWidth: $vuetify.breakpoint.xs,
              smWidth: $vuetify.breakpoint.smAndUp,
            }"
          >
            <v-btn
              class="
                px-8
                py-6
                mt-10
                mb-2
                roundedShinyCard
                box
                disable-events
                hidden-xs-only
              "
              :class="item.classBtn"
              :color="item.color"
              ><h3 class="white--text">{{ item.title }}</h3>
            </v-btn>
            <v-btn
              class="
                px-4
                py-3
                mt-10
                mb-2
                roundedShinyCard
                box
                disable-events
                hidden-sm-and-up
              "
              :class="item.classBtn"
              :color="item.color"
              ><h4 class="white--text">{{ item.title }}</h4>
            </v-btn>
            <v-card-text style="min-height: 180px">
              <p class="font-weight-bold">
                {{ item.description }}
              </p>
            </v-card-text>
            <v-img
              class="white--text align-end"
              :height="$vuetify.breakpoint.xs ? '200px' : '250px'"
              :src="require('../assets/values/' + item.src + '.jpeg')"
            >
            </v-img>
          </v-card>
        </div>
      </div>
    </v-row>
    <v-row justify="center" class="mx-0 hidden-xs-only">
      <v-col
        v-for="(photo, n) in photos"
        :key="n"
        class="d-flex child-flex mx-0 px-0"
        cols="2"
      >
        <v-img :src="photo.url" aspect-ratio="1" class="grey lighten-2">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Values",

  data: () => ({
    photos: [],
    items: [
      {
        src: "learnByPlay",
        color: "red",
        classBtn: "boxRed",
        classOuter: "boxRedOuter",
        title: "Invatare prin joaca",
        description:
          "Asiguram un climat caracterizat prin stil relaxat, de creatie, prin asigurarea libertatii de afirmare si incurajarea efortului creativ. Ne-am propus o activitate educativa care sa evite sablonizarea, urmarind ca, prin joc, sa-i punem in valoare pe copii.",
      },
      {
        src: "food",
        color: "blue",
        classBtn: "boxBlue",
        classOuter: "boxBlueOuter",
        title: "Mancare sanatoasa",
        description:
          "Mancarea oferita este proaspata si sanatoasa. Avem in dotare bucatarie proprie, avand astfel control absolut in privinta asigurarii unei alimentatii corespunzatoare. Copiii servesc masa intr-un spatiu destinat doar acestei activitati.",
      },
      {
        src: "teachers",
        color: "orange",
        classBtn: "boxOrange",

        classOuter: "boxOrangeOuter",
        title: "Profesori calificati",
        description:
          "Echipa Anastasya este formata din cadre didactice cu dubla specializare (educator-invatator) sau absolvente ale Facultatii de Psihologie, inzestrate cu spirit inovator.",
      },
    ],
  }),
  methods: {},
};
</script>
<style scoped lang="scss">
.bgValues {
  background-image: url("../assets/slides/Slide9.jpg");
  width: 100%;
  min-height: 100vh;
  background-size: cover;
}
.roundedShinyCard {
  border-radius: 80px;
  max-width: 430px;
}
.xsWidth {
  width: 340px;
}
.smWidth {
  width: 450px;
}
</style>
