<template>
  <v-container fluid fill-height grid-list-xs class="bgHero">
    <v-row justify="center" align="center" class="mx-0 px-0" id="home">
      <v-col cols="11" offset-sm="2" sm="5">
        <v-chip class="white--text px-3 pt-7 pb-3" color="black" label>
          <div class="subSection">
            <p>Gradinita si Scoala Primara Anastasya</p>
          </div>
        </v-chip>
        <h1 class="mb-3 xlSubheading">Invata prin joaca!</h1>
        <p class="font-weight-bold">
          Au inceput inscrierile pentru anul scolar {{ currentYear - 1 }}-{{
            currentYear
          }}!
        </p>
        <v-btn
          x-large
          color="purple"
          class="white--text boxPurple"
          @click.prevent="$vuetify.goTo('#contact', { offset: 0 })"
          >Aplica acum</v-btn
        >
      </v-col>
      <v-col cols="12" sm="5" class="hidden-xs-only pa-0 ma-0">
        <div class="rotateCard1">
          <v-card width="380px" height="335px" class="pa-4 movingCard">
            <v-img
              width="360px"
              height="300px"
              :src="require('../assets/space.jpeg')"
            />
          </v-card>
        </div>

        <div class="rotateCard2">
          <v-card width="380px" height="335px" class="pa-4 movingCard">
            <v-img
              width="360px"
              height="300px"
              :src="require('../assets/heroImg2.jpeg')"
            >
              <v-container grid-list-xs fill-height>
                <v-row align="center" justify="center">
                  <v-btn x-large color="purple" fab @click="dialog = true"
                    ><v-icon x-large class="white--text"
                      >mdi-play</v-icon
                    ></v-btn
                  >
                </v-row>
              </v-container>
            </v-img>
          </v-card>
        </div>
        <v-dialog v-model="dialog" max-width="1400px" mx-height="1000px">
          <iframe
            width="100%"
            height="850"
            src="https://www.youtube.com/embed/H_FCg02XLXQ?autoplay=1"
            title="Gradinita Anastasya"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </v-dialog>
        <!-- <v-img
          :src="require('../assets/rainbow.png')"
          class="absoluteImg"
          width="95%"
        /> -->
      </v-col>

      <!-- <Sun class="hidden-xs-only" /> -->
    </v-row>
  </v-container>
</template>

<script>
// import Sun from "../components/Sun.vue";

export default {
  name: "Home",
  components: {
    // Sun,
  },

  data: () => ({
    dialog: false,
    currentYear: new Date().getFullYear(),
  }),
};
</script>
<style>
.bgHero {
  background-image: url("../assets/slides/Slide3.jpg");
  width: 100%;
  height: 74vh;
  background-size: cover;
}
.rotateCard1 {
  position: absolute;
  top: 90px;
  transform: rotate(-10deg);
}
.rotateCard2 {
  position: absolute;
  top: 300px;
  right: 100px;
  transform: rotate(10deg);
}
.movingCard {
  animation: MoveUpDown 4s linear infinite;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
.changeWave {
  position: absolute;
  bottom: 0;
  margin-bottom: 370px;
}
.absoluteImg {
  position: absolute;
  transform: rotate(-15deg);
  top: 300px;
  right: -550px;
  z-index: 1;
}

.xlSubheading {
  font-size: 55px;
  font-weight: 500;
}
</style>
