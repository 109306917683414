<template>
  <v-container fluid>
    <v-row class="grey white--text pl-4 pr-7">
      <div
        class="d-flex align-center d-inline-flex"
        v-for="(info, i) in infoList"
        :key="'A' + i"
        cols="2"
      >
        <v-icon color="black" class="ml-5 mr-3">{{ info.icon }}</v-icon>
        <h5 class="black--text">{{ info.title }}</h5>
      </div>
      <v-spacer></v-spacer>
      <div class="pb-4 hidden-xs-only">
        <v-btn
          small
          elevation="1"
          class="mr-3 mt-3"
          fab
          color="white"
          v-for="(social, i) in socials"
          :key="'D' + i"
          target="_blank"
          :href="social.link"
        >
          <v-icon class="" :color="social.color">{{ social.icon }}</v-icon>
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TopNav",

  data: () => ({
    show: false,

    infoList: [
      {
        icon: "mdi-phone",
        title: "0314.361.115",
      },
      {
        icon: "mdi-email",
        title: "office@anastasya.ro",
      },
      {
        icon: "mdi-map-marker",
        title: "Str. Sos Unirii, Nr 327, Corbeanca, Ilfov",
      },
      {
        icon: "mdi-calendar",
        title: "Program: 08:00 - 19:00",
      },
    ],
    socials: [
      {
        icon: "mdi-facebook",
        color: "indigo",
        link: "https://www.facebook.com/people/Anastasya-Gradinita-Scoala/100009468179608/",
      },
      {
        icon: "mdi-youtube",
        color: "red",
        link: "https://www.youtube.com/watch?v=H_FCg02XLXQ",
      },
    ],
  }),
};
</script>
<style scoped></style>
