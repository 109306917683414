<template>
  <v-container fluid grid-list-xs class="bgEntries sectDist">
    <v-row justify="center" class="text-center mb-8 mt-5 mx-0" id="entries">
      <v-col cols="12" sm="4" id="school">
        <div class="subSection">
          <v-chip label class="red"
            ><p class="mb-0 white--text">
              Cauti sa-ti inscrii copilul?
            </p></v-chip
          >
        </div>
        <h1 class="mb-3 black--text font-weight-bold">Pasi inscriere</h1>
      </v-col>
    </v-row>

    <v-container grid-list-xs>
      <v-row justify="center" fill-height align="center">
        <v-col cols="10" md="12">
          <!-- Browser steps version -->
          <v-stepper
            color="purple"
            height="500px"
            class="pushSteps hidden-xs-only"
            elevation="0"
          >
            <v-stepper-header>
              <v-divider thickness="100px"></v-divider>

              <v-stepper-step step=""
                ><v-card
                  min-width="150px"
                  height="200px"
                  class="red positionStep banner"
                >
                  <h1 class="white--text text-center mt-14">01</h1>
                </v-card>
                <div class="positionStepP">
                  <h3 class="mb-2 black--text">
                    Programarea unei vizite in persoana
                  </h3>
                  <p>Prin website, email sau telefonic</p>
                </div>
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step step=""
                ><v-card
                  min-width="150px"
                  height="200px"
                  class="lightPurple positionStep banner"
                >
                  <h1 class="white--text text-center mt-14">02</h1>
                </v-card>
                <div class="positionStepP">
                  <h3 class="mb-2 black--text mt-10">
                    Vizitarea Gradinita Anastasya
                  </h3>
                  <p>Prezentarea ofertei educationale</p>
                </div>
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step step=""
                ><v-card
                  min-width="150px"
                  height="200px"
                  class="orange positionStep banner"
                >
                  <h1 class="white--text text-center mt-14">03</h1>
                </v-card>
                <div class="positionStepP">
                  <h3 class="mb-2 black--text mt-10">
                    Depunerea dosarului de inscriere
                  </h3>
                  <p>Formular, contract, copie acte</p>
                </div>
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step step=""
                ><v-card
                  min-width="150px"
                  height="200px"
                  class="blue positionStep banner"
                >
                  <h1 class="white--text text-center mt-14">04</h1>
                </v-card>
                <div class="positionStepP">
                  <h3 class="mb-2 black--text mt-10">
                    Depunerea dosarului medical
                  </h3>
                  <p>Fisa medicala, lista de vaccinari, etc.</p>
                </div>
              </v-stepper-step>
              <v-divider></v-divider>
            </v-stepper-header>
          </v-stepper>
          <!-- Mobile steps version -->
          <div class="hidden-sm-and-up">
            <v-card width="200px" height="250px" class="red banner mx-auto">
              <v-container fluid fill-height grid-list-xs>
                <v-row align="center" justify="center">
                  <h1 class="white--text text-center">01</h1></v-row
                >
              </v-container>
            </v-card>
            <div class="my-16 text-center">
              <h3 class="mb-2 black--text">
                Programarea unei vizite in persoana
              </h3>
              <p>Prin website, email sau telefonic</p>
            </div>
            <v-card
              width="200px"
              height="250px"
              class="lightPurple banner mx-auto"
            >
              <v-container fluid fill-height grid-list-xs>
                <v-row align="center" justify="center">
                  <h1 class="white--text text-center">02</h1></v-row
                >
              </v-container>
            </v-card>
            <div class="my-16 text-center">
              <h3 class="mb-2 black--text">
                Vizitarea Gradinita si Scoala Primara Anastasya
              </h3>
              <p>Prezentarea ofertei educationale</p>
            </div>
            <v-card width="200px" height="250px" class="orange banner mx-auto">
              <v-container fluid fill-height grid-list-xs>
                <v-row align="center" justify="center">
                  <h1 class="white--text text-center">03</h1></v-row
                >
              </v-container>
            </v-card>
            <div class="my-16 text-center">
              <h3 class="mb-2 black--text">Depunerea dosarului de inscriere</h3>
              <p>Formular, contract, copie acte</p>
            </div>
            <v-card width="200px" height="250px" class="blue banner mx-auto">
              <v-container fluid fill-height grid-list-xs>
                <v-row align="center" justify="center">
                  <h1 class="white--text text-center">04</h1></v-row
                >
              </v-container>
            </v-card>
            <div class="my-16 text-center">
              <h3 class="mb-2 black--text">Depunerea dosarului medical</h3>
              <p>Fisa medicala, lista de vaccinari, etc.</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mdiAirballoon } from "@mdi/js";

export default {
  name: "Entries",
  components: {},
  data: () => ({
    panel: 0,
    dialog: false,

    items: [
      {
        title: "Gradinita",
        icon: mdiAirballoon,
        color: "red",
        age: "2-5",
        spots: "2",
        description: "Urmati pasii din stanga pentru a lua contact cu noi.",
      },
      {
        title: "Clasa Pregatitoare",
        icon: "mdi-human-female-girl",
        color: "purple",
        age: "6",
        spots: "3",
        description: "Urmati pasii din stanga pentru a lua contact cu noi.",
      },
      {
        title: "Scoala Primara",
        icon: "mdi-book-open-page-variant",
        color: "pink",
        age: "7-11",
        spots: "4",

        description: "Urmati pasii din stanga pentru a lua contact cu noi.",
      },
      {
        title: "After-School",
        icon: "mdi-volleyball",
        color: "blue",
        age: "6-11",
        spots: "3",

        description: "Urmati pasii din stanga pentru a lua contact cu noi.",
      },
    ],
    messages: [
      {
        title: "01",
        message: `1. Programarea unei vizite ( prin e-mail sau telefonic )`,
        color: "red",
      },
      {
        message:
          "2. Vizitarea  gradinitei / prezentarea ofertei educationale / discutarea documentatiei necesare inscrierii",
        color: "lightPurple",
      },
      {
        message:
          "3. Depunerea dosarului de inscriere ( formular de inscriere / contract de parteneriat pentru educatie / copie CI parinti / copie  C N  copil  /  fisa de evaluare ( portofoliu )  de la gradinita frecventata anterior",
        color: "blue",
      },
      {
        message:
          "4. Dosarul  medical: fisa de consultatii medicale / adeverinta medicala de intrare in colectivitate / aviz epidemiologic ( valabil  2 zile ) / lista de vaccinari  sau copie dupa carnetul de vaccinari / exudat faringian si nazal ( valabile 7  zile ) / examen coproparazitologic ( valabil 7 zile )",
        color: "yellow",
      },
    ],
  }),
};
</script>
<style scoped>
.borderImg {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.247);
}
.bgEntries {
  background-image: url("../assets/slides/Slide6.jpg");
  width: 100%;
  min-height: 110vh;
  background-size: cover;
}
.positionStep {
  position: absolute;
  left: -40px;
  bottom: 100px;
}
.banner {
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 100%,
    50% calc(100% - 20px),
    0% 100%,
    0% 0%
  );
}
.positionStepP {
  position: absolute;
  width: 220px;
  text-align: center;
  left: -75px;
  bottom: -150px;
}
.pushSteps {
  padding-top: 250px;
}
.theme--light.v-stepper {
  background: none;
}
.theme--light.v-stepper.v-stepper__step__step {
  background: rgba(0, 0, 0, 0.38) !important;
}
</style>
